@use "sass:math";

@import '../../../scss/colors';

.splash {
  background-image: url('./hello-beautiful-50-background.jpg');

  %spacer {
    content: '';
    height: 1px;
    width: 100px;
  }
    
  .spacer-1 {
    @extend %spacer;
    -webkit-flex: 1;
    flex: 1;
  }
  
  .spacer-2 {
    @extend %spacer;
    -webkit-flex: 2.4;
    flex: 2.4;
  }

  .flexbox-group {
    
    display: flex;
    display: -webkit-flex; 
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    height: 526px;
    position:relative;
    
    .hello-beautiful { 
      width: 688px;
      height: 383px; 
      z-index: 10;
      position: relative;
      background-image: url('./hello-beautiful@2x.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    .nav-buttons-container {
      margin-top: 0;
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      white-space: nowrap;
      position: relative;
      right: 0;
      width: 100%;
      bottom: 20px;
      padding-bottom:0;
      z-index: 1;
      
      .nav-button {
        width: 247px;
        font-size: 1.625em;
        line-height: 2.3;
        letter-spacing: -0.035em;

        &:first-of-type { margin-bottom: 22px;}
      }
    }
  }
}

.mybc, .peripartum, .peripartumUTA { 
  .splash {
    .nav-buttons-container .nav-button {
      background-color: $orange;
      border: none;
      
      &:hover { background-color: $dark-orange; }
    }
  }
}

.mybc.splash .mbc-footer .ptbi { display: none; }


@media only screen and (min-width: 835px){
  .splash .spacer-2 { 
    flex: 0.5;
    -webkit-flex: 0.5;
  }
}

@media only screen and (max-width: 719px){
  $almost-full-width: 92vw;

  .splash {

    .spacer-2 { 
      -webkit-flex: 1.7;
      flex: 1.7; 
    }

    .flexbox-group {
      height: 400px;

      .nav-buttons-container{
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        bottom: 10px;
      }

      .hello-beautiful {
        width:$almost-full-width;
        max-width: 340px;
        height: math.div(1, 1.35) * $almost-full-width;
        max-height: 243px;
        background-image: url('./hello-beautiful-mobile@2X.png');
        background-size: contain;
        background-position: bottom;
      }
    }
  }

  footer { padding-left: 20px; }
}