@import '../../../scss/colors';

.main_nav {
  &.mybc {  background-image: url('../../shared/main/main-tm-background.jpg');}
  &.mypathGeneral, &.mypathVA {
    background-image: url('./MyPathMainNav.jpg');
    background-position: left;
  }
  &.peripartum, &.peripartumUTA { 
    background-image:url('./PeripartumMainNav.jpg');
    
    .heading-container h1{ color: $dark_blue }
    .markdown-block p { color: $dark_gray;}
    .main-nav-buttons-container .nav-button:nth-child(6) { 
      background-color: $medium_blue;
    }
  }

 .main-nav-buttons-container .nav-button a .main-nav-arrow{
   margin-left: 2px; 
   
    @keyframes wiggle{
      from { left: 0 }
      50% { left: 0 }
      57% {  left: -1.3vw }
      71% { left: 0.5vw }
      78% { left: -1.3vw }
      92% { left: 0.5vw }
      to { left: 0}
    }

    &.wiggle {
      animation-name: wiggle;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-delay: 1.5s;
    }
  }
}

@media only screen and (min-width: 835px){
  .main_nav { background-image: url('../../shared/main/main-desktop-background.jpg');}
}

// @media only screen and (max-width: 670px) {
//   .main_nav.es .main-nav-buttons-container .nav-button a p { font-size: 3.25vw }
// }

// @media only screen and (min-width: 501px) and (max-width: 670px) {
//   .main_nav.es .main-nav-buttons-container .nav-button a p {
//     font-size: 18px;
//   }
// }

//508
.mypathVA.main_nav {
  .main-nav-buttons-container .nav-button { 
    color: black;
    padding-left: 20px; 
    
    &:nth-child(1) { background: linear-gradient(90deg, $orange 20px, #FFFFFF 20px);}
    &:nth-child(2) { background: linear-gradient(90deg, $dark_orange 20px, #FFFFFF 20px);}
    &:nth-child(3) { background: linear-gradient(90deg, $lighter_green 20px, #FFFFFF 20px);}
    &:nth-child(4) { background: linear-gradient(90deg, $dark_green 20px, #FFFFFF 20px);}
    &:nth-child(5) { background: linear-gradient(90deg, $light_blue 20px, #FFFFFF 20px);}
    &:nth-child(6) { background: linear-gradient(90deg, $dark_gray_blue 20px, #FFFFFF 20px);}

    &.visited {
      &:nth-child(1) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $orange 20px, #FFFFFF 20px);}
      &:nth-child(2) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_orange 20px, #FFFFFF 20px);}
      &:nth-child(3) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $lighter_green 20px, #FFFFFF 20px);}
      &:nth-child(4) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_green 20px, #FFFFFF 20px);}
      &:nth-child(5) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $light_blue 20px, #FFFFFF 20px);}
      &:nth-child(6) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_gray_blue 20px, #FFFFFF 20px);}
     }

  }
}
