// COLORS = %w[red green blue orange yellow purple black brown pink silver].freeze

@import '../../../scss/colors';
@import '../../../scss/mixins';

.study_mp {
  background-image: url('../../../shared_images/splash-mp-new-background.jpg');
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@media only screen and (min-width: 835px){
  .study_mp .flex-group .heading-container { padding-top: 0;}
}

@media only screen and (max-width: 719px){
  .study_mp {
    width: 100vw;
    
    .nav-buttons-container{ .nav-button { z-index: 15;} }
  }
}

main {
  background-color: antiquewhite;
  border-radius: 10px;
  margin: auto;
  max-width: 600px;
  padding: 4vw;
  text-align: center;

  p {
    margin: 20px auto;
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 700px) {
  main {
    margin: 40px;
    padding: 20px;
  }
}