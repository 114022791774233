@use "sass:math";

@import '../../../scss/colors';
@import '../../../scss/mixins';

$image-container-width: 348px;
$uncropped-image-width: 479px;
$image-height: 717px;
$heading-line-height: 39px;
$heading-pad-top: 20px;
$heading-pad-btm: 16px;
$nav-container-total-height: 70px;
$heading-total-height: 1 * $heading-line-height + $heading-pad-btm + $heading-pad-top;
$heading-total-height-es: 2 * 35px + $heading-pad-btm + $heading-pad-top;
$rainbow-height: 15px;
$rainbow-heading-and-nav-total-height: $nav-container-total-height + $heading-total-height + $rainbow-height;
$vertical-buffer: 8vw;
$min-page-height: $image-height + $heading-total-height;
$min-page-height-es: $image-height + $heading-total-height-es;
$min-page-height-desktop: $heading-total-height + $rainbow-height + 812px;
$menu-item-size: 200px;

.breastfeeding.peripartum, .breastfeeding.peripartumUTA, .breastfeeding.superDev {
  display: block;
  padding: 0;
  position: relative;
  // height: calc(100% - #{$rainbow-height});
  min-height: $min-page-height;

  .heading-container, .markdown-block { 
    padding-left: 5vw;
    padding-right: 5vw; 
  }

  .nav-buttons-container {
    padding: 0 5vw 30px 5vw;
  }

  // &.en .image-space { margin-top: calc(100vh - #{$min-page-height}); }
  // &.es .image-space { margin-top: calc(100vh - #{$min-page-height-es}); }
  // &.en.safari.not-standalone .image-space { margin-top: calc(100vh - 31px - #{$min-page-height}); }
  // &.es.safari.not-standalone .image-space { margin-top: calc(100vh - 31px - #{$min-page-height-es}); }
  // &.en .image-space { margin-top: calc(100vh - #{$min-page-height}); }
  // &.safari .image-space .nav-buttons-container{ 
  //   bottom: -16px;
  // }
  .image-space {
    width: $image-container-width;
    height: $image-height;
    shape-outside: url('./breastfeeding-full.png');
    float: right;
    background-size: $uncropped-image-width $image-height;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url('./breastfeeding-uncropped.png');
    shape-margin: 2%;
    margin-top: 137px;
    position: relative;
  }

  .flex-group { padding-bottom: 30px;}

  .menu-item { 
    @include flexbox-menu-item;
    margin: 0 0 0 5vw;
    width: $menu-item-size;
    height: $menu-item-size;
    z-index: 10;
  }

  .menu-item-modal .modal-content.first {
    h1 { background-image: url('../ImportantInformation/breastfeeding.svg')}

    .markdown-block { padding: 0}
  }
}

@media only screen and (min-width: 835px){
  .breastfeeding.peripartum, .breastfeeding.peripartumUTA, .breastfeeding.superDev {
    min-height: $rainbow-height + $image-height;
  }
}

@media only screen and (min-width: 1000px){
  .breastfeeding.peripartum, .breastfeeding.peripartumUTA, .breastfeeding.superDev {
    display: flex; 

    .heading-container {
      padding: 20px 0 4px; 
      align-self: center;
    }

    .flex-group, .heading-container { max-width: 885px; }

    .flex-group {
      .markdown-block { padding: 0};

      .image-space { 
        width: $uncropped-image-width;
        // margin-top: calc(100vh - #{$min-page-height});
        background-size: $uncropped-image-width $image-height;
        background-repeat: no-repeat;
      }
    }

    .menu-item { margin-left: 0}
  }
}

@media only screen and (max-width: 719px){
  .breastfeeding.peripartum, .breastfeeding.peripartumUTA, .breastfeeding.superDev {
    height: auto;
    .overlay .menu-item-modal { @include mobile-full-screen-menu-item-modal }

    .heading-container h1{
      line-height: 1.5;
      white-space: initial;
    }

    .flex-group {
      display: flex;
      flex-wrap: wrap;

      .image-space {
        shape-outside: none;
        height: math.div($image-height, 2) + 25px;
        width: math.div($image-container-width, 2);
        background-size: math.div($uncropped-image-width, 2) math.div($image-height, 2);
        min-height: 0px;
        float: none;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .markdown-block {
        p, h6, li {  @include mobile-paragraph-style;   }
      }

      .menu-item { 
        order: 2;
        margin-bottom: math.div($image-height, 2) - 270px;
      }
    }

    .nav-buttons-container.fake.mobile { position: relative}
  }
}

@media only screen and (min-height: 961px) and (min-width: 720px) {
  .breastfeeding.peripartum, .breastfeeding.peripartumUTA, .breastfeeding.superDev {
    .flex-group .image-space { height: calc(#{$image-height} + 100vh - 960px) ; }

    &.safari.not-standalone .flex-group .image-space { height: calc(#{$image-height} + 100vh - 960px - 31px) ; }
  }
}
